.fieldset {
  container-type: inline-size;
  margin-block: var(--betterplace-spacing-300);
}

.headingMargin {
  margin-block-end: var(--betterplace-spacing-200);
}

.radio {
  display: inline-block;
  width: 100%;
}

.radioLabel {
  width: 100%;
}

.labelContent {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.paymentMethodIcon {
  width: unset !important;
  height: 100% !important;
  fill: none;
}

.skeletonContainer {
  block-size: 3rem;
  padding-block: 0.25rem;
}
